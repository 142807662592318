import * as React from 'react';

import { AppNavBarComponent } from 'components/header-nav/Nav';
import {
  Box,
  GDSStyleObject,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { useLocation } from '@leagueplatform/routing';

export const APP_NAV_DEFAULT_HEIGHT = 8 * 7; // 56px – 7 lines on the Genesis 8px grid
export const APP_NAV_MOBILE_HEIGHT = APP_NAV_DEFAULT_HEIGHT * 2; // Mobile Nav has 2 rows, so twice the default height

type BasePageProps = React.PropsWithChildren<{
  css?: GDSStyleObject;
  isPrivatePage?: boolean;
}>;

export const BasePage: React.FC<BasePageProps> = ({
  children,
  isPrivatePage = true,
  css,
}) => {
  // Scroll to top on location change
  const { pathname } = useLocation();
  const isMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const MAIN_CONTENT_MIN_HEIGHT = `calc(100vh - ${
    isMobile ? APP_NAV_MOBILE_HEIGHT : APP_NAV_DEFAULT_HEIGHT
  }px)`;

  return (
    <>
      {isPrivatePage && <AppNavBarComponent />}
      <Box
        as="main"
        css={{
          position: 'relative',
          flex: '1',
          overflowX: 'hidden',
          minHeight: MAIN_CONTENT_MIN_HEIGHT,
          ...css,
        }}
      >
        {children}
      </Box>
    </>
  );
};
